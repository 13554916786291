import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import bugfix from '../../images/undraw_fixing_bugs.svg';

function ServicesTestingPage() {

    return <ContentPage>
        <h1>Testing</h1>
        <p>Proper testing of applications is often an afterthought, and too often it is not done at all. It is rarely 
            sufficient for a user just to have a quick tour of an application to check that it seems to be working.</p>
        <PageImage image={bugfix} alt='Test and fix' />
        <p>We can develop structured test programmes that exercise an application to verify that it is working 
            correctly. The scope of the testing can include:</p>
        <ul>
            <li>Confirmation that the application meets the technical specification.</li>
            <li>Commissioning testing as part of a wider system.</li>
            <li>Acceptance testing.</li>
            <li>Performance and stress testing.</li>
        </ul>
        <p>At Coralpeak Systems, we firmly believe that any test failure needs to be documented in detail. Too often, 
            testers will give only a pass or fail result with vague comments about what was wrong.</p>
        <p>Our tests record the steps to reproduce an error, the expected result, and what happened in the system.  
            With this information, the developers can immediately begin to diagnose and fix the error rather than 
            wasting time trying to recreate it and understand what is failing.</p>
    </ContentPage>

}

export default ServicesTestingPage;